import "./App.css";
import MaintPage from "./maintPage";

const App = () => {
  return (
    <div className="App">
      <MaintPage />
    </div>
  );
};

export default App;
