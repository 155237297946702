import { Typography, Stack, Link } from "@mui/material";

const MaintPage = () => {
  return (
    <div className="generic-grid">
      <div className="grid-title">
        <div className="title-bar">
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography variant="h5" sx={{ fontWeight: "bold", textTransform: "uppercase", color: "white" }}>
              Gametje.
            </Typography>
          </Stack>
        </div>
      </div>
      <div className="generic-content">
        <Stack alignItems="center" justifyContent="center" sx={{ mt: 3, mb: 3 }}>
          <div className="ai-concept-image">
            <img src={"robot-no-body-parts.webp"} alt="gametje ai robot" height="70px" />
          </div>
          <Typography variant="h5">The site is currently down for maintenance.</Typography>
        </Stack>
      </div>
      <div className="generic-description">.</div>

      <div className="generic-footer">
        <Stack direction="row" alignItems="center" justifyContent="center">
          <Typography color="#828282" align="center" sx={{ ml: 1 }}>
            {"Copyright © "}
            <Link color="inherit" href="https://gametje.com/">
              gametje.com
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Stack>
      </div>
    </div>
  );
};

export default MaintPage;
